<template>
  <div class="pt-container" id="patient-credit">
    <div class="pt-header">
      <div class="pt-title">Crédito do paciente</div>

      <div class="gap-action-buttons no-print">
        <Loading
          :class="{ reload: true, loading: loading }"
          @click="() => !loading && getBills()"
        />

        <b-button
          class="wh-button"
          variant="outline-primary"
          outline
          @click="getXlsx"
        >
          Download xlsx
        </b-button>
      </div>
    </div>

    <div class="row no-print d-flex flex-row align-items-end">
      <div class="col-2">
        <PatientInput v-model="patient" />
      </div>

      <b-button
        class="filter-button mb-2"
        variant="outline-primary"
        v-b-toggle.credit-patient__filters
      >
        <FilterIcon class="mr-2"/>
        Filtros
      </b-button>

      <b-sidebar
        id="credit-patient__filters"
        bg-variant="white"
        no-header
        right
        shadow
      >
        <div class="column mx-2 credit-patient__filters-body">
          <div
            class="d-flex flex-row align-items-center justify-content-between mb-3"
          >
            <div class="sidebar-header">Filtros</div>
            <CloseIcon class="icon" v-b-toggle.credit-patient__filters />
          </div>

          <!-- paciente -->
          <div>
            <PatientInput v-model="patient" />
          </div>

          <!-- tipo -->
          <div>
            <b-form-group id="bill_type">
              <label for="bill_type">Tipo</label>
              <Autocomplete
                v-model="billType"
                :options="billTypes"
                placeholder="Selecionar"
              />
            </b-form-group>
          </div>


          <!-- periodo -->
          <div>
            <b-form-group class="form-group">
              <div class="label">
                <label for="search" id="input-title">Período</label>
              </div>

              <Periods @select="handlePeriod" />
            </b-form-group>
          </div>


        <!-- metodo pagamento -->
          <div>
            <b-form-group class="form-group">
              <label for="search">Método de recebimento</label>
              <CheckboxSelect
                id="payment-methods"
                placeholder="Selecionar"
                optionLabel="label"
                trackBy="value"
                v-model="payment_method"
                :value="selectedPaymentMethod"
                :options="payment_methods"
                v-on:onChange="changeSelectedPaymentMethods"
                :key="payment_methods.length"
              />
            </b-form-group>
          </div>

          <!-- conta bancaria -->
          <div>
            <b-form-group id="field_bank_account">
              <label for="field_bank_account">Conta bancária</label>
              <Autocomplete
                v-model="bank_account"
                :options="bank_accounts"
                placeholder="Selecionar"
              />
            </b-form-group>
          </div>

          <div class="d-flex border-top my-1">
            <b-button
              class="w-100 mt-2"
              variant="primary"
              outline
              @click="getBills"
              v-b-toggle.credit-patient__filters
            >
              Confirmar
            </b-button>
          </div>
        </div>
      </b-sidebar>
    </div>

    <PatientCreditTable :bills="bills" :onEdit="onEditBills"/>

    <div class="amount-position">
      <div class="toPay">
        <p>
          Valor total saídas <span>{{ parseNumberToMoney(sumToPay) }} </span>
        </p>
      </div>
      <div class="toPay">
        <p>
          Valor saídas pág. {{ this.page }} de {{ this.last_page }}
          <span>{{ getPageSum('bill_to_pay') }} </span>
        </p>
      </div>

      <div class="toReceive">
        <p>
          Valor entradas pág. {{ this.page }} de {{ this.last_page }}
          <span>{{ getPageSum('bill_to_receive') }} </span>
        </p>
      </div>
      <div class="toReceive">
        <p>
          Valor total entradas <span>{{ parseNumberToMoney(sumToReceive) }} </span>
        </p>
      </div>
    </div>

    <div class="pagination-position no-print">

      <b-pagination
        size="sm"
        v-model="page"
        :total-rows="count"
        :per-page="limit"
        first-number
        last-number
      />
    </div>
  </div>
</template>

<script>
import api from '../api'
import { EventBus } from '@/utils/eventBus'
import { saveAs } from 'file-saver'
import { parseNumberToMoney } from '@/utils/moneyHelper'
import { getCurrentClinic } from '../../../utils/localStorageManager'


export default {
  name: 'creditpatient-tab',
  components: {
    Autocomplete: () => import('@/components/Autocomplete'),

    CheckboxSelect: () => import('@/components/CheckboxSelect'),
    Loading: () => import('@/assets/icons/loading.svg'),
    PatientInput: () => import('@/components/General/PatientInput'),
    FilterIcon: () => import('@/assets/icons/filter.svg'),
    CloseIcon: () => import('@/assets/icons/close.svg'),
    PatientCreditTable: () =>
      import('@/modules/financial/components/PatientCreditTable'),
    Periods: () => import('@/components/General/Periods')

  },
  props: {
    onEditBillsToPay: Function,
    onEditBillsToReceive: Function
  },
  async mounted() {
    await this.getBills()
    await this.getCategories()
    await this.getCreators()
    await this.getPaymentsMethods()
    await this.getBankAccounts()
    await this.getPatientDepositCategory()
    this.getCostCenters()
    EventBus.$on('reloadBillsToPay', async () => await this.getBills())
    EventBus.$on('reloadBillsToReceive', async () => await this.getBills())
  },

  data() {
    const clinic = getCurrentClinic()
    return {
      loading: false,
      bills: [],
      period: null,
      periodStatus: 'service_date',
      periodOrder: 'asc',
      query: '',
      page: 1,
      limit: 0,
      count: 0,
      clinicId: clinic.id,
      installments: [],
      billsToPay: [],
      category: null,
      categories: [],
      creator_id: null,
      creators: [],
      status_payment: null,
      start: null,
      end: null,
      expirationType: null,
      beneficiary: null,
      professional: null,
      professionals: [],
      patient: null,
      patients: [],
      payment_method: [],
      payment_methods: [],
      bank_account: null,
      bank_accounts: [],
      sumInstallmentAmountOfPage: 0,
      sumToPay: 0,
      sumToReceive: 0,
      last_page: 0,
      creditPatientCategory: null,

      status_payments: [
        { label: 'Vencido', value: 'expired' },
        { label: 'Pago', value: 'paid_out' },
        { label: 'Pendente', value: 'pending' },
        { label: 'Parcelado', value: 'installments' }
      ],

      costCenters: [],
      selectedPaymentMethod: [],
      selectedCostCenters: [],
      costCenterOptions: [],

      billType:  '',
      billTypes: [
        { label: 'Saída', value: 'bill_to_pay' },
        { label: 'Entrada', value: 'bill_to_receive' },
        { label: 'Todos', value: '' }
      ]
    }
  },

  methods: {
    parseNumberToMoney,
    openBillsToReceiveModal() {
      this.$bvModal.show('selected-bills-to-receive-modal')
    },
    openBillsToPayModal() {
      this.$bvModal.show('selected-pay-modal')
    },
    onEditBills(bill, hasBillItems = false) {
      const billId = bill?.bill_credit?.bill_id ? bill?.bill_credit?.bill_id : bill?.id
      this.onEditBillsToReceive(
          billId,
          bill?.bill_items?.length
        )
    },
    async getBills() {
      this.bills = []
      const isLoading = this.$loading.show()
      try {
        const response = await api.getBills(this.page, this.getFilters())

        if (response.data) {
          this.bills = response.data?.data
          this.count = response.data.total
          this.limit = response.data.per_page
          this.last_page = response.data.last_page
          
          this.sumToPay = response.data?.sumToPay
          this.sumToReceive = response.data?.sumToReceive
        }
      } catch (error) {
        this.$toast.error(error.message)
        throw error
      } finally {
        isLoading.hide()
      }
    },
    getFilters() {
      return {
        type: this.billType || null,
        query: this.query,
        status_payment: this.status_payment,
        start: this.start,
        end: this.end,
        patient: this.patient?.id,
        payment_method: this.payment_method,
        bank_account: this.bank_account,
        period_status: this.periodStatus,
        period_srder: this.periodOrder,
        creator_id: this.creator_id,
        clinic_id: this.clinicId,
        isCreditPatient: true

      }
    },
    async getCategories() {
      try {
        const response = await api.getCategories(this.clinicId, 'Saída')
        this.categories = response.data.map(category => ({
          ...category,
          label: `${category.name}`,
          value: `${category.id}`
        }))
      } catch (error) {
        throw new Error(400)
      }
    },

    async getProfessionals() {
      try {
        const response = await this.api.showClinicProfessionals(this.clinicId)
        this.professionals = response.data.map(professional => ({
          ...professional,
          label: `${professional.name}`,
          value: `${professional.id}`
        }))
      } catch (error) {
        throw new Error(400)
      }
    },

    async getCreators() {
      try {
        const response = await this.api.showClinicProfessionals(this.clinicId)
        this.creators = response.data.map(creator => ({
          ...creator,
          label: `${creator.name}`,
          value: `${creator.id}`
        }))
      } catch (error) {
        throw new Error(400)
      }
    },

    async getPaymentsMethods() {
      try {
        const response = await api.getPaymentMethodsBillToReceive(this.clinicId)
        this.payment_methods = response.data.map(payment => ({
          ...payment,
          label: `${payment.name}`,
          value: `${payment.id}`
        }))
      } catch (error) {
        throw new Error(400)
      }
    },

    async getBankAccounts() {
      try {
        const response = await api.getBankAccountBillToReceive(this.clinicId)
        this.bank_accounts = response.data.map(bank => ({
          ...bank,
          label: `${bank.name}`,
          value: `${bank.id}`
        }))
      } catch (error) {
        throw new Error(400)
      }
    },

    changeSelectedCostCenters(value) {
      this.costCenters = value.map(costCenter => costCenter.value)
    },

    changeSelectedPaymentMethods(value) {
      this.payment_method = value.map(paymentMethod => paymentMethod.value)
    },

    getCostCenters() {
      api
        .getCostCenters(this.clinicId)
        .then(res => {
          this.costCenterOptions = []
          res.data.map(center => {
            if (center.active) {
              this.costCenterOptions.push({
                value: center.id,
                label: center.name
              })
            }
          })
        })
        .catch(err => {
          this.$toast.error(err.message)
        })
    },

    handlePeriod(value) {
      this.start = value.start
      this.end = value.end
    },

    getXlsx() {
      const isLoading = this.$loading.show()
      const filters = this.getFilters()

      filters.category = this.creditPatientCategory?.id
      
      api
        .getCompleteBillsExport(this.clinicId, filters)
        .then(res => {
          saveAs(
            new Blob([res.data], {
              type: 'application/vnd.ms-excel'
            }),
            'contas.xlsx'
          )
        })
        .catch(err => this.$toast.error(err.message))
        .finally(() => isLoading.hide())
    },
    getPageSum(type) {
      const sum = this.bills.reduce((acc, bill) => {
        if (bill?.type === type) {
          if (bill?.type === 'bill_to_receive') {
            acc += bill?.final_amount
          }else {
            acc += bill?.amount
          }
        }
        return acc
      }, 0)
      return this.parseNumberToMoney(sum)
    },
    async getPatientDepositCategory() {
      if (this.clinicId) {
        const response = await api.getCreditPatientCategory(this.clinicId)

        if (response) {
          this.creditPatientCategory = response.data
        }
      }
    },
  },

  watch: {
    async page() {
      await this.getBills()
    },
    async query() {
      await this.getBills()
    },
    async start() {
      await this.getBills()
    },
    async patient() {
      await this.getBills()
    }
  }
}
</script>

<style scoped lang="scss">
.pt-container {
  padding: 30px 10px;

  .pt-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0 30px 0;

    .gap-action-buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
    }
  }

  .pt-title {
    display: inline-flex;
    align-items: center;
    font-family: 'Red Hat Display';
    font-weight: 500;
    font-size: 18px;
  }

  .icon {
    height: 24px;
    width: 24px;
    margin-left: 5px;
    stroke: var(--neutral-500);
  }

  .filter-button {
    display: flex;
    flex-direction: row;
    align-items: center;

    height: 42px;
  }

  .pagination-position {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 20px 0 !important;
  }
  .amount-position {
    display: flex;
    flex-direction: row-reverse;

    .toPay {
      background: var(--light-orange-200);
    }

    .toReceive {
      background: var(--states-success-soft-green);
    }

    div {
      padding: 8px 16px;
      border-radius: 8px;
      margin-left: 10px;
      justify-content: center;

      p {
        font-size: 16px;
        line-height: 150%;
        color: var(--type-active);

        span {
          font-weight: 700;
          color: var(--blue-500);
        }
      }
    }
  }

  label {
    width: 100%;
    font-family: 'Nunito Sans';
    font-weight: 700;
    font-size: 16px;
    color: var(--type-active);
    text-align: left !important;
    color: var(--dark-blue);
    margin-bottom: 4px;
  }

  .label {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: minmax(auto, 24px);

    .status {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;

      .status-input {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 13.5px;
        gap: 4px;
      }
    }
  }

  #input-title {
    font-family: 'Nunito Sans' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 150% !important;
    color: var(--type-active);
  }
}

.wh-button {
  color: var(--blue-500);
}
.order-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  fill: var(--type-active);
}
.sidebar-header {
  //styleName: Titles / H3;
  padding: 10px 0;
  font-family: Red Hat Display;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  color: #0c1d59;
}

.credit-patient__filters-body {
  padding: 5px;

  label {
    font-weight: 400;
  }
}
</style>
<style lang="scss">
#patient-credit {

  .patient-form-container {
    .help {
      display: none;
    }
  }
}
</style>